/* Blockquotes */
blockquote {
  margin: 0 0 1.25em;
  padding: .5625em 1.25em 0 1.1875em;
  border-left: 1px solid #ddd; }

blockquote, blockquote p {
  line-height: 1.6; }

p, blockquote, dt, td.content, span.alt, summary {
  font-size: 1.125em; }

p, blockquote, dt, td.content {
  font-size: .975em;
  orphans: 3;
  widows: 3; }

.quoteblock {
  margin: 0 1em 0 1.5em;
  display: table; }

.quoteblock:not(.excerpt) > .title {
  margin-left: -1.5em;
  margin-bottom: .75em; }

.quoteblock blockquote, .quoteblock p {
  line-height: 1.75;
  word-spacing: .1em;
  letter-spacing: 0;
  text-align: justify; }

.quoteblock blockquote {
  margin: 0;
  padding: 0;
  border: 0; }

.quoteblock blockquote::before {
  content: "\201c";
  float: left;
  font-size: 2.75em;
  font-weight: bold;
  line-height: .6em;
  margin-left: -.6em;
  color: #7a2518;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1); }

.quoteblock blockquote > .paragraph:last-child p {
  margin-bottom: 0; }

.quoteblock .attribution {
  margin-top: .75em;
  margin-right: .5ex;
  text-align: right; }

.verseblock {
  margin: 0 1em 1.25em; }

.verseblock pre {
  font-family: $base-font-family;
  font-size: 1.15rem;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 300;
  text-rendering: optimizeLegibility; }

.verseblock pre strong {
  font-weight: 400; }

.verseblock .attribution {
  margin-top: 1.25rem;
  margin-left: .5ex; }

.quoteblock .attribution, .verseblock .attribution {
  font-size: .9375em;
  line-height: 1.45;
  font-style: italic;
  color: $grey-color;
}

.quoteblock .attribution br, .verseblock .attribution br {
  display: none; }

.quoteblock .attribution cite, .verseblock .attribution cite {
  display: block;
  letter-spacing: -.025em;
  color: rgba(0, 0, 0, 0.6); }

.quoteblock.abstract blockquote::before, .quoteblock.excerpt blockquote::before, .quoteblock .quoteblock blockquote::before {
  display: none; }

.quoteblock.abstract blockquote, .quoteblock.abstract p, .quoteblock.excerpt blockquote, .quoteblock.excerpt p, .quoteblock .quoteblock blockquote, .quoteblock .quoteblock p {
  line-height: 1.6;
  word-spacing: 0; }

.quoteblock.abstract {
  margin: 0 1em 1.25em;
  display: block; }

.quoteblock.abstract > .title {
  margin: 0 0 .375em;
  font-size: 1.15em;
  text-align: center; }

.quoteblock.excerpt > blockquote, .quoteblock .quoteblock {
  padding: 0 0 .25em 1em;
  border-left: .25em solid #dddddf; }

.quoteblock.excerpt, .quoteblock .quoteblock {
  margin-left: 0; }

.quoteblock.excerpt blockquote, .quoteblock.excerpt p, .quoteblock .quoteblock blockquote, .quoteblock .quoteblock p {
  color: inherit;
  font-size: 1.0625rem; }

.quoteblock.excerpt .attribution, .quoteblock .quoteblock .attribution {
  color: inherit;
  font-size: .85rem;
  text-align: left;
  margin-right: 0; }

.subheader, .admonitionblock td.content > .title, .audioblock > .title, .exampleblock > .title, .imageblock > .title, .listingblock > .title, .literalblock > .title, .stemblock > .title, .openblock > .title, .paragraph > .title, .quoteblock > .title, table.tableblock > .title, .verseblock > .title, .videoblock > .title, .dlist > .title, .olist > .title, .ulist > .title, .qlist > .title, .hdlist > .title {
  line-height: 1.45;
  // color: #7a2518;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: .25em; }

.audioblock, .imageblock, .literalblock, .listingblock, .stemblock, .videoblock {
  margin-bottom: 1.25em; }

.admonitionblock td.content > .title, .audioblock > .title, .exampleblock > .title, .imageblock > .title, .listingblock > .title, .literalblock > .title, .stemblock > .title, .openblock > .title, .paragraph > .title, .quoteblock > .title, table.tableblock > .title, .verseblock > .title, .videoblock > .title, .dlist > .title, .olist > .title, .ulist > .title, .qlist > .title, .hdlist > .title {
  text-rendering: optimizeLegibility;
  text-align: left;
  // font-family: "Noto Serif","DejaVu Serif",serif;
  font-size: .9em;
  font-weight: lighter; }

.literalblock pre, .listingblock > .content > pre {
  border-radius: 4px;
  overflow-x: auto;
  padding: 1em;
  font-size: .8125em; }

@media screen and (min-width: 768px) {
  .literalblock pre, .listingblock > .content > pre {
    font-size: .90625em; } }
@media screen and (min-width: 1280px) {
  .literalblock pre, .listingblock > .content > pre {
    font-size: 1em; } }
.literalblock pre, .listingblock > .content > pre:not(.highlight), .listingblock > .content > pre[class=highlight], .listingblock > .content > pre[class^="highlight "] {
  background: #f7f7f8; }

.literalblock.output pre {
  color: #f7f7f8;
  background: rgba(0, 0, 0, 0.9); }

.literalblock + .colist, .listingblock + .colist {
  margin-top: -.5em; }
