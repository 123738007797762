/* Lists */
ul, ol, dl {
  line-height: 1.6;
  margin-bottom: 1.25em;
  list-style-position: outside;
  font-family: inherit; }

ul, ol {
  margin-left: 1.5em; }

ul li ul, ul li ol {
  margin-left: 1.25em;
  margin-bottom: 0; }

ul.square li ul, ul.circle li ul, ul.disc li ul {
  list-style: inherit; }

ul.square {
  list-style-type: square; }

ul.circle {
  list-style-type: circle; }

ul.disc {
  list-style-type: disc; }

ol li ul, ol li ol {
  margin-left: 1.25em;
  margin-bottom: 0; }

dl dt {
  margin-bottom: .3125em;
  font-weight: bold; }

dl dd {
  margin-bottom: 1.25em; }

ol {
  margin-left: 1.75em; }

ul li ol {
  margin-left: 1.5em; }

dl dd {
  margin-left: 1.125em; }

dl dd:last-child, dl dd:last-child > :last-child {
  margin-bottom: 0; }

li p, ul dd, ol dd, .olist .olist, .ulist .ulist, .ulist .olist, .olist .ulist {
  margin-bottom: .625em; }

ul.checklist, ul.none, ol.none, ul.no-bullet, ol.no-bullet, ol.unnumbered, ul.unstyled, ol.unstyled {
  list-style-type: none; }

ul.no-bullet, ol.no-bullet, ol.unnumbered {
  margin-left: .625em; }

ul.unstyled, ol.unstyled {
  margin-left: 0; }

li > p:empty:only-child::before {
  content: "";
  display: inline-block; }

ul.checklist > li > p:first-child {
  margin-left: -1em; }

ul.checklist > li > p:first-child > .fa-square-o:first-child, ul.checklist > li > p:first-child > .fa-check-square-o:first-child {
  width: 1.25em;
  font-size: .8em;
  position: relative;
  bottom: .125em; }

ul.checklist > li > p:first-child > input[type=checkbox]:first-child {
  margin-right: .25em; }

ul.inline {
  display: flex;
  flex-flow: row wrap;
  list-style: none;
  margin: 0 0 .625em -1.25em; }

ul.inline > li {
  margin-left: 1.25em; }

.unstyled dl dt {
  font-weight: 400;
  font-style: normal; }

ol.arabic {
  list-style-type: decimal; }

ol.decimal {
  list-style-type: decimal-leading-zero; }

ol.loweralpha {
  list-style-type: lower-alpha; }

ol.upperalpha {
  list-style-type: upper-alpha; }

ol.lowerroman {
  list-style-type: lower-roman; }

ol.upperroman {
  list-style-type: upper-roman; }

ol.lowergreek {
  list-style-type: lower-greek; }
