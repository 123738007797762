/* Font-based Admonition and Inline Icons */

span.icon > .fa {
  cursor: default;
}

.admonitionblock {
  table, th, td {
    // color: #828282;
    color: $grey-color;
    border: none;

    a { 
      color: $grey-color;
  
      &:hover {
        color: $grey-color;
      }   
  
      &:visited {
        color: $grey-color;
      }   
    }

  }
}

.admonitionblock td.icon {
  text-align: right;
  vertical-align: top;
  padding: 3px 5px;
  width: 20px;

  [class^="fa icon-"] {
    font-size: 1.0em;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
    cursor: default;
  }

  .icon-note:before {
    content: "\f05a";
    color: #19407c;
  }

  .icon-tip:before {
    content: "\f0eb";
    text-shadow: 1px 1px 2px rgba(155, 155, 0, 0.8);
    color: #111;
  }

  .icon-warning:before {
    content: "\f071";
    color: #bf6900;
  }

  .icon-caution:before {
    content: "\f06d";
    color: #bf3400;
  }

  .icon-important:before {
    content: "\f06a";
    color: #bf0000;
  }
}
