@charset "utf-8";

$base-font-family: -apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif;
$base-font-size:   1em;
$base-font-weight: 400;
$small-font-size:  $base-font-size * 0.875;
$base-line-height: 1.5;
$base-code-font-family: Monaco,"Courier New",Courier,monospace;

$spacing-unit:     0.85em;

$text-color:       #111;
$background-color: #fdfdfd;
$brand-color:      rgba(0, 0, 0, 0.8);

$grey-color:       #828282;
$grey-color-light: lighten($grey-color, 40%);
$grey-color-dark:  darken($grey-color, 25%);

// Width of the content area
$on-palm:          600px;
$on-tablet:        1080px;
$on-laptop:        1280px;

@mixin media-query($device) {
  @media only screen and (max-width: $device) {
    @content;
  }
}

// Import partials from `sass_dir` (defaults to `_sass`)
@import "base", "layout", "highlight", "asciidoc", "asciidoc.ext", "font-awesome";
