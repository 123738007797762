/* Circled Callout Numbers */

.colist {
  table, th, td {
    border: none;
  }
}

.conum[data-value] {
  $radius-size: 1.5em;
  display: inline-block;
  color: #fff !important;
  background: rgba(0, 0, 0, 0.8);
  -webkit-border-radius: 1em;
  border-radius: 1em;
  text-align: center;
  font-size: .75em;
  width: $radius-size;
  height: $radius-size;
  line-height: $radius-size;
  font-family: $base-font-family;
  font-style: normal;
  font-weight: bold;

  * {
    color: #fff !important;
  }

  + b {
    display: none;
  }

  &::after {
    content: attr(data-value);
  }
}

pre .conum[data-value] {
  position: relative;
  top: -.125em;
}

b.conum * {
  color: inherit !important;
}

.conum:not([data-value]):empty {
  display: none;
}
